
// $body-bg: #efefef;
$font-family-base: Roboto,"Helvetica Neue",Arial,sans-serif;

$font-size-base:              0.9rem;
$line-height-base:            1.8;

$primary:       #0071bc !default;
$info:       #a9ccff !default;
$assets:    #be53b6 !default;

$theme-colors: (
  "assets": $assets
);

@import "~bootstrap/scss/bootstrap";

.or-list {

    display: inline-block;

    li, dt {
        text-align: left;
    }

    dl {
        text-align: left;
        text-decoration: underline;
        margin: 0;
    }

}

.or-blank {
    font-size: 20px;
    padding-bottom: 3px;
    bottom: 3px;
    position: relative;
    display: block;
}

.or-header-img {
    width: 150px;
    height: auto;
}

.or-post-img {
    width: 280px;
    height: auto;
}

.or-w-fixed {
    width: 120px;
}

.or-brand-img {
    width: 30px;
    height: 30px;
}

.or-service-icon {

    * {
        margin-right: 8px;
        font-size: 1.1rem;
    }
}

.typeform-style {
    display:inline-block;text-decoration:none;background-color:#267DDD;color:white;cursor:pointer;font-family:Helvetica,Arial,sans-serif;font-size:20px;line-height:50px;text-align:center;margin:0;height:50px;padding:0px 33px;border-radius:25px;max-width:100%;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;font-weight:bold;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;
    
}