
.or-icons {
    font-size: 20px;
    padding-bottom: 3px;
    bottom: 3px;
    position: relative;
    display: inline-block;
}
.workana-icon {
    width: 20px;
    height: auto;
    padding: 1px;
}